import { useCallback, useEffect, useState } from "react";
import { Events } from "@discord-external/activity-iframe-sdk";
import useWebSocket, { ReadyState } from "react-use-websocket";

import { discordSdk } from "../utils/discordSdk";
import { useDiscordAuthContext } from "../hooks/useDiscordAuthContext";
import {
  GameMessage,
  MessageType,
  WSMessage,
  createMessage,
} from "../../shared/types/websocketMessage";
import { GameState, GameStates } from "../../shared/types/game-states";
import { RoundLayout } from "./RoundLayout";
import { GlobalElements } from "./GlobalElements";
import { LobbyLayout } from "./LobbyLayout";

const getWebsocketUrl = () => {
  const url = new URL(location.href);

  if (url.hostname === "localhost") {
    return `ws://localhost:8788/api/game`;
  }

  return `wss://${url.hostname}/api/game`;
};

const getInstanceId = () => {
  const url = new URL(location.href);

  const override = url.searchParams.get("instanceId");

  return override ?? discordSdk.instanceId;
};

const Game = () => {
  const [userId, setUserId] = useState<string | null>(null);
  const [gameState, setGameState] = useState<GameState>(null);
  const { channelId, guildId } = discordSdk;
  const { access_token } = useDiscordAuthContext();

  const wsUrl = `${getWebsocketUrl()}/${getInstanceId()}`;

  const {
    sendJsonMessage: sendMessage,
    lastJsonMessage: wsMessage,
    readyState,
  } = useWebSocket(wsUrl, {
    onClose() {
      setUserId(null);
    },
    onError(event) {
      console.error(event);
    },
    shouldReconnect: () => true,
    reconnectAttempts: 10,
    reconnectInterval: (attemptNumber) =>
      // exponential reconnect backoff: 1, 2, 4, 8, 10. Max of 10 seconds between retries.
      Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
  });

  const handleSpeakingStart = useCallback(({ user_id }) => {
    console.log(`${user_id} is speaking!`);
  }, []);

  const handleSpeakingStop = useCallback(({ user_id }) => {
    console.log(`${user_id} stopped speaking!`);
  }, []);

  useEffect(() => {
    discordSdk.subscribe(Events.SPEAKING_STOP, handleSpeakingStop, {
      channel_id: channelId,
    });
    discordSdk.subscribe(Events.SPEAKING_START, handleSpeakingStart, {
      channel_id: channelId,
    });
    return () => {
      discordSdk.unsubscribe(Events.SPEAKING_STOP, handleSpeakingStop);
      discordSdk.unsubscribe(Events.SPEAKING_START, handleSpeakingStart);
    };
  }, [
    discordSdk.subscribe,
    discordSdk.unsubscribe,
    handleSpeakingStart,
    handleSpeakingStop,
  ]);

  const handleGameStateUpdate = (message: GameMessage.GameStateUpdate) => {
    const msg = createMessage<GameMessage.Pong>(MessageType.Pong);
    sendMessage(msg);

    setGameState(message.data as GameState);
  };

  const handleMessage = useCallback((message: WSMessage) => {
    switch (message.type) {
      case MessageType.Authenticated:
        console.log(`authenticated as ${message.data.user.id}`);
        setUserId(message.data.user.id);
        break;
      case MessageType.GameStateUpdate:
        handleGameStateUpdate(message);
        break;
      default:
        console.log("unhandled message", message);
    }
  }, []);

  useEffect(() => {
    if (wsMessage == null) return;

    let messages = wsMessage;

    if (!Array.isArray(wsMessage)) {
      messages = [wsMessage];
    }

    (messages as WSMessage[]).forEach(handleMessage);
  }, [wsMessage, handleMessage]);

  useEffect(() => {
    if (readyState !== ReadyState.OPEN || userId != null) return;

    const msg = createMessage<GameMessage.Authenticate>(
      MessageType.Authenticate,
      {
        access_token,
        channelId,
        guildId,
      }
    );

    sendMessage(msg);
  }, [readyState, userId]);

  if (gameState == null) {
    return <>Loading...</>;
  }

  const user = gameState.users.find((u) => u.id === userId);

  if (user == null) {
    return <>Loading...</>;
  }

  if (
    gameState.state === GameStates.Lobby ||
    gameState.state === GameStates.PreGame ||
    gameState.state === GameStates.PostGame
  ) {
    return (
      <>
        <GlobalElements gameState={gameState} user={user} />
        <LobbyLayout
          users={gameState.users}
          user={user}
          onReadyClick={() => {
            sendMessage(createMessage<GameMessage.Ready>(MessageType.Ready));
          }}
          onSpectateClick={() => {
            sendMessage(
              createMessage<GameMessage.Spectate>(MessageType.Spectate)
            );
          }}
          isPostGame={gameState.state === GameStates.PostGame}
        />
      </>
    );
  }

  if (
    gameState.state === GameStates.InRound ||
    gameState.state === GameStates.PostRound
  ) {
    return (
      <>
        <GlobalElements gameState={gameState} user={user} />
        <RoundLayout
          user={user}
          question={gameState.questionData}
          onAnswerClick={(answer: string) => {
            if (
              gameState.state !== GameStates.InRound ||
              user.isSpectator ||
              answer === user.selectedAnswer
            )
              return;

            sendMessage(
              createMessage<GameMessage.AnswerQuestion>(
                MessageType.AnswerQuestion,
                {
                  answer,
                }
              )
            );
          }}
        />
      </>
    );
  }
};

export default Game;
