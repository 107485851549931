import { GameState, GameStates } from "../../shared/types/game-states";
import { UserPill } from "./UserPill";
import { Timer } from "./Timer";

interface Props {
  user?: User;
  gameState?: GameState;
}

export const GlobalElements = ({ user, gameState }: Props) => {
  const userSubtitle =
    gameState.state !== GameStates.Lobby ? `${user.score} points` : undefined;

  const logoUrl = new URL("../images/logo-black.png", import.meta.url).href;

  return (
    <>
      {user && (
        <div className="absolute top-4 left-6">
          <UserPill
            user={user}
            subtitle={user.isSpectator ? "spectating" : userSubtitle}
          />
        </div>
      )}

      <div className="absolute top-0 right-6">
        <img src={logoUrl} alt="logo" className="h-24" />
      </div>

      {gameState.approxTimeLeft && (
        <div className="absolute top-24 right-16">
          <Timer timeLeft={gameState.approxTimeLeft} />
        </div>
      )}
    </>
  );
};
