import clsx from "clsx";
import { PublicQuestion, Question } from "../../shared/types/game";
import { QuestionCategory } from "../../shared/types/question-helpers";

interface Props {
  question: Question | PublicQuestion;
}

export const QuestionCard = ({ question }: Props) => {
  return (
    <div
      className={clsx(
        "flex items-center justify-center bg-white relative rounded-lg ring-4 ring-black text-black mx-8 shadow-xl", // default classes
        "h-[40vh]", // more defaults
        "lg:w-[50vw] lg:px-8 lg:aspect-video lg:h-[unset]" // lg overrides
      )}
    >
      <span className="font-medium absolute top-4 right-8 text-base xl:text-xl 2xl:text-4xl">
        {QuestionCategory[question.category] ?? "Unknown Category"}
      </span>
      <div
        className={clsx(
          "font-bold text-black text-center leading-normal text-xl",
          "lg:text-3xl xl:!leading-tight 2xl:text-5xl"
        )}
      >
        {question.question.text}
      </div>
    </div>
  );
};
