interface Props {
  timeLeft: number;
}

export const Timer = ({ timeLeft }: Props) => {
  return (
    <div className="flex items-center justify-center ring-8 ring-black w-40 h-40 rounded-full">
      <div className="font-bold font-brand text-8xl text-center">
        {timeLeft}
      </div>
    </div>
  );
};
