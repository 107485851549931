import { PublicGameState } from "./game-states";

type TypedMessage<T extends MessageType, D> = {
  type: T;
  time: number;
  data: D;
};

export enum MessageType {
  AnswerQuestion = "answer-question",
  Authenticate = "authenticate",
  Authenticated = "authenticated",
  GameStateUpdate = "game-state-update",
  Pong = "pong",
  Ready = "ready",
  Spectate = "spectate",
  UserDisconnected = "user-disconnected",
}

export namespace GameMessage {
  export type AnswerQuestion = TypedMessage<
    MessageType.AnswerQuestion,
    {
      answer: string;
    }
  >;
  export type Authenticate = TypedMessage<
    MessageType.Authenticate,
    {
      access_token: string;
      guildId: string;
      channelId: string;
    }
  >;
  export type Authenticated = TypedMessage<
    MessageType.Authenticated,
    {
      user: PublicUser;
    }
  >;
  export type GameStateUpdate = TypedMessage<
    MessageType.GameStateUpdate,
    PublicGameState
  >;
  export type Pong = TypedMessage<MessageType.Pong, never>;
  export type Ready = TypedMessage<MessageType.Ready, never>;
  export type Spectate = TypedMessage<MessageType.Spectate, never>;
  export type UserDisconnected = TypedMessage<
    MessageType.UserDisconnected,
    {
      userId: string;
    }
  >;
}

export type WSMessage =
  | GameMessage.AnswerQuestion
  | GameMessage.Authenticate
  | GameMessage.Authenticated
  | GameMessage.GameStateUpdate
  | GameMessage.Pong
  | GameMessage.Ready
  | GameMessage.Spectate
  | GameMessage.UserDisconnected;

export function createMessage<T extends WSMessage>(
  type: T["type"],
  data: T["data"] = undefined
): T {
  return <T>{
    type,
    time: Date.now(),
    data,
  };
}
