import { FC } from "react";
import clsx from "clsx";
import { userPingToColor } from "../utils/userPingToColor";

interface Props {
  user: User;
  showPoints: boolean;
  className?: string;
}

export const UserColumnCard: FC<Props> = ({
  className = "",
  user,
  showPoints = false,
}) => {
  const subtitleText = user.isSpectator
    ? "Spectating"
    : user.isReady
    ? "Ready!"
    : "Not Ready";

  return (
    <div
      className={clsx("flex flex-col items-center text-center w-36", className)}
    >
      <span className="relative">
        <img
          className="inline-block h-24 w-24 rounded-full"
          src={user.avatarSrc}
          alt={user.display_name}
        />
        <span
          className={clsx(
            "absolute bottom-0 right-0 block h-6 w-6 rounded-full ring-4 ring-white",
            userPingToColor(user.ping)
          )}
        />
      </span>
      <span className="text-lg font-bold text-black text-ellipsis leading-tight mt-2">
        {user.display_name}
      </span>
      <span className="text-2xl font-black font-brand text-black leading-tight mt-0">
        {showPoints ? `${user.score} points` : subtitleText}
      </span>
    </div>
  );
};
