import clsx from "clsx";

interface Props {
  isSelected: boolean;
  children?: React.ReactNode;
  onClick: () => void;
}

export const BigButton = ({ isSelected, children, onClick }: Props) => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        "text-5xl font-black font-brand py-8 px-16 rounded-lg shadow-xl transition-all duration-200 cursor-pointer xl:w-[35vw] 2xl:w-[20vw]",
        isSelected &&
          "bg-green-600 ring-green-900 ring-4 text-white hover:bg-green-700",
        !isSelected && "hover:bg-gray-200 bg-white"
      )}
    >
      {children}
    </button>
  );
};
