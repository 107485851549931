import clsx from "clsx";
import { ReactNode } from "react";
import { userPingToColor } from "../utils/userPingToColor";

interface Props {
  user: User;
  subtitle?: ReactNode;
}

export const UserPill = ({ user, subtitle }: Props) => {
  return (
    <div className="flex items-center">
      <span className="relative inline-block">
        <img
          className="inline-block h-10 w-10 rounded-full"
          src={user.avatarSrc}
          alt={user.display_name}
        />
        <span
          className={clsx(
            "absolute bottom-0 right-0 block h-3 w-3 rounded-full ring-2 ring-white",
            userPingToColor(user.ping)
          )}
        />
      </span>
      <div className="ml-3 flex flex-col justify-start">
        <span className="text-sm font-medium text-black text-ellipsis leading-tight">
          {user.display_name}
        </span>
        <span className="text-sm font-medium text-black leading-tight">
          {subtitle}
        </span>
      </div>
    </div>
  );
};
