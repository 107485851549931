import { BigButton } from "./BigButton";
import { UserColumnCard } from "./UserColumnCard";

interface Props {
  users: Array<User>;
  user: User;
  onReadyClick: () => void;
  onSpectateClick: () => void;
  isPostGame: boolean;
}

export const LobbyLayout = ({
  users,
  user,
  onReadyClick,
  onSpectateClick,
  isPostGame = false,
}: Props) => {
  const displayUsers = users.sort((a, b) => b.score - a.score);
  return (
    <div className="flex w-full h-screen items-center justify-center">
      <div className="flex flex-col self-center items-center justify-between 2xl:w-[50vw] w-[75vw]">
        <div className="flex flex-row justify-center w-full bg-white rounded-xl shadow-xl py-4">
          {displayUsers.map((user) => (
            <UserColumnCard
              key={user.id}
              user={user}
              showPoints={isPostGame}
              className="mx-2"
            />
          ))}
        </div>
        {!isPostGame && (
          <div className="flex flex-row justify-between w-full mt-8">
            <BigButton isSelected={user.isReady} onClick={onReadyClick}>
              {user.isReady ? "Ready!" : "Ready Up!"}
            </BigButton>
            <BigButton isSelected={user.isSpectator} onClick={onSpectateClick}>
              Spectate!
            </BigButton>
          </div>
        )}
      </div>
    </div>
  );
};
