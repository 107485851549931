import clsx from "clsx";

interface Props {
  answer: string;
  correctAnswer?: string;
  onAnswerClick: (answer: string) => void;
  isSelected: boolean;
}

export const AnswerCard = ({
  answer,
  correctAnswer,
  isSelected,
  onAnswerClick,
}: Props) => {
  const isCorrect = correctAnswer === answer;

  const isDefaultState = !isSelected && !isCorrect;

  let textSize = "text-4xl 2xl:text-5xl";

  if (answer.length > 15) {
    textSize = "text-3xl 2xl:text-4xl";
  }

  if (answer.length > 30) {
    textSize = "text-2xl 2xl:text-3xl";
  }

  if (answer.length > 60) {
    textSize = "text-md 2xl:text-3xl";
  }

  if (answer.length > 120) {
    textSize = "text-sm 2xl:text-2xl";
  }

  if (answer.length > 150) {
    textSize = "text-xs 2xl:text-xl";
  }

  return (
    <div
      className={clsx(
        "flex items-center justify-center relative rounded-lg w-[35vw] h-[8vw] px-16 cursor-pointer shadow-xl",
        isDefaultState && "bg-white text-black",
        isCorrect && "bg-green-600 text-white",
        isSelected && {
          ["ring-8 ring-indigo-900 bg-indigo-600 text-white"]:
            correctAnswer == null,
          ["ring-8 ring-green-900"]: isCorrect,
          ["ring-8 ring-red-900 bg-red-600 text-white"]:
            !isCorrect && correctAnswer != null,
        }
      )}
      onClick={() => onAnswerClick(answer)}
    >
      <div className={`font-bold ${textSize} text-center leading-snug`}>
        {answer}
      </div>
    </div>
  );
};
