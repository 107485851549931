import { PublicQuestion, Question } from "../../shared/types/game";
import { AnswerCard } from "./AnswerCard";
import { QuestionCard } from "./QuestionCard";

interface Props {
  user: User;
  question: Question | PublicQuestion;
  onAnswerClick: (answer: string) => void;
}

export const RoundLayout = ({ user, question, onAnswerClick }: Props) => {
  return (
    <>
      <div className="flex flex-col items-center justify-between h-screen flex-grow pt-32 lg:py-8">
        <QuestionCard question={question} />
        <div className="mt-2">
          <div className="grid grid-cols-2 grid-rows-2 gap-x-28 gap-y-4">
            {question.answers.map((answer) => {
              return (
                <AnswerCard
                  key={answer}
                  answer={answer}
                  onAnswerClick={onAnswerClick}
                  isSelected={user.selectedAnswer === answer}
                  correctAnswer={(question as Question).correctAnswer}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
