import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { discordSdk as sdk } from "../utils/discordSdk";

interface DiscordAuth {
  access_token: string;
  user: {
    username: string;
    discriminator: string;
    id: string;
    avatar?: string;
    public_flags: number;
  };
  scopes: string[];
  expires: string;
  application: {
    description: string;
    icon?: string;
    id: string;
    rpc_origins?: string[];
    name: string;
  };
}

const DiscordAuthContext = createContext<DiscordAuth>({
  access_token: "",
  scopes: [],
  expires: "",
  user: {
    id: "",
    username: "",
    discriminator: "",
    avatar: undefined,
    public_flags: 0,
  },
  application: {
    rpc_origins: undefined,
    id: "",
    name: "",
    icon: undefined,
    description: "",
  },
});

const DiscordAuthContextProvider = ({ children }: { children: ReactNode }) => {
  const authContext = useAuthContextSetup();

  if (authContext == null) {
    return <>loading...</>;
  }

  return (
    <DiscordAuthContext.Provider value={authContext}>
      {children}
    </DiscordAuthContext.Provider>
  );
};

const useDiscordAuthContext = () => {
  return useContext(DiscordAuthContext);
};

const useAuthContextSetup = () => {
  const [auth, setAuth] = useState(null);
  const settingUp = useRef(false);

  useEffect(() => {
    const setUpDiscordSdk = async () => {
      await sdk.ready();

      // authorize with client
      const { code } = await sdk.commands.authorize({
        client_id: process.env.DISCORD_CLIENT_ID,
        response_type: "code",
        state: "",
        prompt: "none",
        scope: ["identify", "guilds", "guilds.members.read", "rpc.voice.read"],
      });

      const response = await fetch("/api/token", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code }),
      });

      const { access_token } = await response.json();

      // authenticate with client
      const newAuth = await sdk.commands.authenticate({ access_token });

      setAuth({ ...newAuth });
    };

    if (!settingUp.current) {
      settingUp.current = true;
      setUpDiscordSdk();
    }
  }, [sdk]);

  return auth;
};

export { DiscordAuthContextProvider, useDiscordAuthContext };
