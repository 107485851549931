export type TaggedState<T extends string> = {
  state: T;
  time: number;
  approxTimeLeft?: number;
  users: Array<User>;
};

export enum GameStates {
  Lobby = "lobby",
  PreGame = "preGame",
  InRound = "inRound",
  PostRound = "postRound",
  PostGame = "postGame",
}

export type Lobby = TaggedState<GameStates.Lobby>;

export type PreGame = TaggedState<GameStates.PreGame> & {
  questions: Array<Question>;
};

export type InRound = TaggedState<GameStates.InRound> & {
  questions: Array<Question>;
  roundNumber: number;
  questionData: PublicQuestion;
};

export type PostRound = TaggedState<GameStates.PostRound> & {
  questions: Array<Question>;
  roundNumber: number;
  questionData: Question;
};

export type PostGame = TaggedState<GameStates.PostGame> & {
  winnerId: string;
  questions: Array<Question>;
};

export type GameState = Lobby | PreGame | InRound | PostRound | PostGame;

export type PublicGameState = Omit<GameState, "users" | "questions"> & {
  users: Array<PublicUser>;
  questions?: Array<Question>;
};
